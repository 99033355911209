
import Tabla from "@/components/servicios/TablaServiciosProgramados.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Page from "@/layout/Page.vue";
import { AuthModule, ServiciosProgramadosModule } from "@/store/modules";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "servicios-programados-listado",
  components: { Page, Tabla },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const module = getModule(ServiciosProgramadosModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const error = computed(() => module.errors);

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });
    const cargaListado = ref(false);

    onBeforeMount(async () => {
      cargaListado.value = true;
      try {
        await module.fetchListado(comarcaId);
      } catch (err) {
        Swal.fire({
          text: t("formularios.serviciosProgramadosListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("menu.serviciosProgramados"), [
        t("menu.servicios"),
      ]);
    });

    return {
      servicios: computed(() => module.servicios),
      cargaListado,
      crearNuevo: () => {
        router.push("/listados/servicios-programados/nuevo");
      },
    };
  },
});
