import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Tabla = _resolveComponent("Tabla")!
  const _component_Page = _resolveComponent("Page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    actions: _withCtx(() => [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.crearNuevo
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("tabla.serviciosProgramadosListado.crear.boton")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_Tabla, {
        servicios: _ctx.servicios,
        esProyecto: false
      }, null, 8, ["servicios"]), [
        [_directive_loading, _ctx.cargaListado]
      ])
    ]),
    _: 1
  }))
}